import React, { useState } from 'react';
import Location from '../static/blogImages/map.png';
import Email from '../static/blogImages/gmail.png';

import '../styles/Contacts.css';


const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const [status, setStatus] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    return (
      <div className="contactUs-container">
        <div className="contactUs-form-container">
          <h3>Write Us</h3>
          <form target="_blank"  className="form-box" action="https://formsubmit.co/eb8ced2cedadf5d18f2c798aade0f069" method="POST">
            <div className="name">
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="email">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="message">
              <label htmlFor="message">Message:</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                rows="5"
              ></textarea>
            </div>
            <button type="submit" className="submit-button">
              Send
            </button>
          </form>
          {status && <p className="">{status}</p>}
        </div>
        <div className="contactUs-inf">
          <div className="contactUs-inf-title">
            <div className=""><h3>Contact information</h3></div>
            <div className=""><p>We're open for any suggestion or just to have a chat</p></div>
          </div>
          <div className="contactUs-inf-content">
            <div className="icon-box"><img
              src={Location}
              alt="location"
              className="icon"
            /></div>
            <div className=""><p><strong>Location:</strong> Canada.</p></div>
          </div>
          <div className="contactUs-inf-content">
          <div className="icon-box"><img
              src={Email}
              alt="location"
              className="icon"
            /></div>
            <div className=""><p><strong>Email:</strong> sawas.main.ca@gmail.com</p></div>
          </div>
        </div>
      </div>
    );
};

export default ContactForm;