import image1 from './blogImages/valentinesDay.png';
import image2 from './blogImages/giftsVDay.png';
import image3 from './blogImages/easter.png';
import image4 from './blogImages/Ottawa.png';
import image5 from './blogImages/toronto.png';
import image6 from './blogImages/Montreal.png';
import image7 from './blogImages/spring.png';

const posts = [
  {
    id: "post1",
    title: "5 Best Activities for Valentine’s Day",
    imageTitle: image1,
    content: {
      contentSumari:
        "Valentine’s Day is a celebration of love and connection, making it the perfect occasion to plan something special for your significant other. Whether you prefer a cozy evening indoors or an adventurous day outdoors, here are five of the best activities to make this Valentine’s Day unforgettable.",
      contentBody: [
        {
          contentTitle: "1. Romantic Dinner Date",
          contentParagraph: [
            {
              paragraph:
                "There’s nothing more classic than a romantic dinner date to set the mood. Treat your partner to a fine dining experience at a restaurant known for its ambiance or prepare a homemade meal for an intimate touch. Decorate your dining area with candles, flowers, and soft music to create a cozy atmosphere.",
            },
            {
              paragraph:
                "Pro Tip: Recreate the meal you had on your first date or try cooking a new cuisine together to add a personal and adventurous flair.",
            },
          ],
        },
        {
          contentTitle: "2. Couples Spa Day",
          contentParagraph: [
            {
              paragraph:
                "Unwind and relax with your partner by indulging in a couples spa day. Opt for a spa that offers Valentine’s Day packages, including massages, aromatherapy, and champagne. This activity is perfect for de-stressing and spending quality time together.",
            },
            {
              paragraph:
                "At Home Option: Set up a DIY spa experience with scented candles, bath salts, face masks, and soothing music for a relaxing evening in.",
            },
          ],
        },
        {
          contentTitle: "3. Outdoor Adventure",
          contentParagraph: [
            {
              paragraph:
                "If you both love the outdoors, plan a scenic hike, a sunset picnic, or a romantic hot air balloon ride. In colder climates, activities like ice skating, skiing, or snowshoeing can be a unique way to celebrate.",
            },
            {
              paragraph:
                "Pro Tip: Pack a thermos with hot chocolate or mulled wine to enjoy together during your adventure.",
            },
          ],
        },
        {
          contentTitle: "4. Movie Night at Home",
          contentParagraph: [
            {
              paragraph:
                "Transform your living room into a cozy cinema for a Valentine’s movie night. Pick a lineup of romantic comedies or nostalgic films you both love. Add blankets, pillows, and a variety of snacks for a comfortable and intimate experience.",
            },
            {
              paragraph:
                "Extra Idea: Create a love-themed playlist to play during breaks for added romance.",
            },
          ],
        },
        {
          contentTitle: "5. Create a Memory Together",
          contentParagraph: [
            {
              paragraph:
                "Valentine’s Day is an opportunity to celebrate your bond and show appreciation for one another. Whether you’re enjoying a romantic dinner, relaxing at a spa, or creating lasting memories, the key is to spend quality time together. Choose an activity that resonates with your relationship and make this Valentine’s Day truly unforgettable.",
            },
          ],
        },
        {
          contentTitle: "Conclusion",
          contentParagraph: [
            {
              paragraph:
                "Make Valentine’s Day memorable by learning or creating something new together. Take a cooking or painting class, learn a new dance style, or even write love letters to each other. Engaging in an activity that results in a keepsake makes the day even more special.",
            },
            {
              paragraph:
                "Unique Idea: Create a scrapbook or vision board together to celebrate your past memories and plan your future dreams as a couple.",
            },
          ],
        },
      ],
    },
    author: "AI",
    date: "January 29, 2025",
  },
  {
    id: "post2",
    title: "The 5 Best Gifts for Valentine’s Day in 2025",
    imageTitle: image2,
    content: {
      contentSumari:
        "Valentine’s Day is a celebration of love and connection, making it the perfect occasion to plan something special for your significant other. Whether you prefer a cozy evening indoors or an adventurous day outdoors, here are five of the best activities to make this Valentine’s Day unforgettable.",
      contentBody: [
        {
          contentTitle: "1. Personalized Jewelry 💍✨",
          contentParagraph: [
            {
              paragraph:
                "Jewelry has always been a classic Valentine’s Day gift, but personalized pieces add an extra sentimental touch. Consider a necklace, bracelet, or ring engraved with your partner’s initials, your anniversary date, or a meaningful phrase. Birthstone jewelry and nameplate designs are also trending in 2025, making them a stylish and heartfelt gift.",
            },
            {
              paragraph: "🔹 Gift Ideas:",
            },
            {
              paragraph: "- Custom name necklace",
            },
            {
              paragraph:
                "- Engraved bracelet with coordinates of a special place",
            },
            {
              paragraph: "- Matching couple rings",
            },
          ],
        },
        {
          contentTitle: "2. A Romantic Getaway 🏝️❤️",
          contentParagraph: [
            {
              paragraph:
                "Why settle for a single gift when you can give an unforgettable experience? Plan a surprise weekend trip to a romantic destination. Whether it’s a cozy cabin in the mountains, a luxury beach resort, or a charming city escape, this is the perfect way to create lasting memories.",
            },
            {
              paragraph: "🔹 Top Getaway Ideas:",
            },
            {
              paragraph: "- Wine country retreat 🍷",
            },
            {
              paragraph: "- Beach vacation 🏖️",
            },
            {
              paragraph: "- Spa weekend for relaxation 💆‍♀️💆",
            },
          ],
        },
        {
          contentTitle: "3. High-Tech Love Tokens 📱💘",
          contentParagraph: [
            {
              paragraph:
                "For the tech-savvy partner, why not gift something innovative yet meaningful? Smart gadgets can add convenience, fun, and a bit of romance to your relationship.",
            },
            {
              paragraph: "🔹 Best Tech Gift Ideas:",
            },
            {
              paragraph:
                "- Lovebox Messenger – A cute wooden box that displays love notes sent via an app",
            },
            {
              paragraph:
                "- Customized digital photo frame – Load it with your favorite pictures and videos",
            },
            {
              paragraph:
                "- Smart jewelry – Rings or bracelets that light up when your partner is thinking of you",
            },
          ],
        },
        {
          contentTitle: "4. A Heartfelt DIY Gift 🎨💞",
          contentParagraph: [
            {
              paragraph:
                "A handmade gift shows effort, thoughtfulness, and love. DIY presents are perfect if you want to give something truly unique and personal.",
            },
            {
              paragraph: "🔹 Creative DIY Gift Ideas:",
            },
            {
              paragraph:
                "- Memory Scrapbook 📖 – Collect photos, love notes, and memories from your relationship",
            },
            {
              paragraph:
                '- “Open When” Letters 💌 – Write a series of letters for different occasions (e.g., "Open when you miss me," "Open when you need a laugh")',
            },
            {
              paragraph:
                "- Homemade Coupon Book 🎟️ – Create vouchers for date nights, massages, or breakfast in bed",
            },
          ],
        },
        {
          contentTitle: "5. A Subscription Box for Couples 📦💕",
          contentParagraph: [
            {
              paragraph:
                "Subscription boxes are the gift that keeps on giving! Choose a box tailored to your partner’s interests, whether it’s gourmet snacks, self-care items, or fun date night activities.",
            },
            {
              paragraph: "🔹 Best Subscription Box Ideas:",
            },
            {
              paragraph:
                "- Date Night Box – Monthly at-home date experiences with games, recipes, and activities",
            },
            {
              paragraph:
                "- Gourmet Chocolate or Wine Subscription 🍫🍷 – Perfect for foodies and wine lovers",
            },
            {
              paragraph:
                "- Self-Care Box 🛁 – Spa essentials, skincare products, and relaxation goodies",
            },
          ],
        },
        {
          contentTitle: "Final Thoughts",
          contentParagraph: [
            {
              paragraph:
                "No matter what gift you choose, the most important thing is the thought and love behind it. Whether it’s a luxurious getaway, a sentimental keepsake, or a creative DIY present, make this Valentine’s Day special by celebrating your unique connection.",
            },
            {
              paragraph:
                "💖 Which of these gifts would you love to give (or receive) this Valentine’s Day?",
            },
          ],
        },
      ],
    },
    author: "AI",
    date: "January 29, 2025",
  },
  {
    id: "post3",
    title:
      "Top 5 Best Things to Do for Easter: Fun & Meaningful Ways to Celebrate 🐣🌸",
    imageTitle: image3,
    content: {
      contentSumari:
        "Easter is a time of renewal, joy, and celebration. Whether you’re looking for family-friendly activities, meaningful traditions, or something creative to do, this guide will help you make the most of the holiday! Here are the top five best things to do for Easter to create lasting memories.",
      contentBody: [
        {
          contentTitle: "1. Plan an Easter Egg Hunt 🥚🎉",
          contentParagraph: [
            {
              paragraph:
                "Nothing says Easter like an exciting egg hunt! This classic tradition is perfect for families, friends, and even community gatherings.",
            },
            {
              paragraph: "🔹How to Make It Extra Special:",
            },
            {
              paragraph:
                "✔ Themed Hunts – Try glow-in-the-dark eggs for an evening hunt or color-coded eggs for different age groups.",
            },
            {
              paragraph:
                "✔ Golden Egg Surprise – Hide a special golden egg with a bigger prize inside, such as candy, money, or a small toy.",
            },
            {
              paragraph:
                "✔ DIY Egg Decorating – Let kids personalize their own eggs before the hunt for an added creative touch.",
            },
            {
              paragraph:
                "Egg hunts bring laughter and fun to Easter, making it a must-do activity!",
            },
          ],
        },
        {
          contentTitle: "2. Enjoy a Delicious Easter Brunch 🍽️🐰",
          contentParagraph: [
            {
              paragraph:
                "Easter brunch is the perfect way to gather with loved ones and enjoy a festive meal. Whether you cook at home or visit a restaurant, make sure your menu is filled with springtime favorites!",
            },
            {
              paragraph: "🔹Brunch Ideas:",
            },
            {
              paragraph: "🥞 Fluffy pancakes with fruit and whipped cream",
            },
            {
              paragraph: "🍳 Deviled eggs & honey-glazed ham",
            },
            {
              paragraph: "🥕 Carrot cake cupcakes for a sweet treat",
            },
            {
              paragraph:
                "☕ Spring-inspired drinks like mimosas or lavender lattes",
            },
            {
              paragraph:
                "Pair your meal with spring flowers, pastel decorations, and Easter music to create the perfect festive atmosphere.",
            },
          ],
        },
        {
          contentTitle: "3. Create Easter-Themed DIY Crafts 🎨🐣",
          contentParagraph: [
            {
              paragraph:
                "Unleash your creativity with Easter DIY projects! Whether you’re crafting with kids or making beautiful decorations, these ideas add a personal touch to the holiday.",
            },
            {
              paragraph: "🔹Fun Craft Ideas:",
            },
            {
              paragraph:
                "✂ Handmade Easter cards – Send personalized greetings to family and friends.",
            },
            {
              paragraph:
                "🌸 Easter wreaths – Use pastel flowers, ribbons, and eggs to make a front-door masterpiece.",
            },
            {
              paragraph:
                "🎭 Bunny masks & headbands – A fun craft for kids to wear during celebrations.",
            },
            {
              paragraph:
                "Crafting is a great way to spend quality time together and create keepsakes for future Easters.",
            },
          ],
        },
        {
          contentTitle: "4. Give Back with an Easter Charity Activity ❤️🐇",
          contentParagraph: [
            {
              paragraph:
                "Easter is also about kindness and giving. Take time to spread joy by helping others in your community.",
            },
            {
              paragraph: "🔹Ways to Give Back:",
            },
            {
              paragraph:
                "🤝 Donate Easter baskets – Fill baskets with treats and toys for children in need.",
            },
            {
              paragraph:
                "🏡 Visit senior homes – Bring handmade cards or small gifts to brighten someone’s day.",
            },
            {
              paragraph:
                "🍞 Volunteer at a food bank – Help provide meals to families during the holiday.",
            },
            {
              paragraph:
                "Acts of kindness make Easter even more meaningful and fulfilling.",
            },
          ],
        },
        {
          contentTitle: "5. Take a Springtime Getaway 🌿🌞",
          contentParagraph: [
            {
              paragraph:
                "Celebrate Easter with a mini getaway to enjoy the beauty of spring. Whether it’s a road trip, a picnic in the park, or a weekend at a cozy cabin, being in nature makes Easter feel magical.",
            },
            {
              paragraph: "🔹Perfect Easter Destinations:",
            },
            {
              paragraph:
                "🏞 Botanical gardens – Walk among blooming flowers and take stunning photos.",
            },
            {
              paragraph:
                "🌊 Beach trip – Enjoy the sun, sand, and fresh ocean air.",
            },
            {
              paragraph:
                "🌄 Countryside escape – Visit a farm, go horseback riding, or stay in a charming Airbnb.",
            },
            {
              paragraph:
                "A getaway is a refreshing way to welcome the new season and relax with loved ones.",
            },
          ],
        },
        {
          contentTitle: "Final Thoughts 🌸🐣",
          contentParagraph: [
            {
              paragraph:
                "Easter is about joy, family, and renewal. Whether you’re hunting for eggs, enjoying a delicious brunch, making crafts, giving back, or taking a trip, these activities make Easter unforgettable.",
            },
            {
              paragraph: "How do you plan to celebrate Easter this year?",
            },
          ],
        },
      ],
    },
    author: "AI",
    date: "January 30, 2025",
  },
  {
    id: "post4",
    title: "5 Best Things to Do in Ottawa",
    imageTitle: image4,
    content: {
      contentSumari:
        "As Canada’s capital, Ottawa is a vibrant city packed with history, culture, and natural beauty. Whether you’re visiting for the first time or looking for new adventures, here are the five best things to do in Ottawa.",
      contentBody: [
        {
          contentTitle: "1. Explore Parliament Hill",
          contentParagraph: [
            {
              paragraph:
                "No trip to Ottawa is complete without a visit to Parliament Hill. The iconic Gothic-style buildings house Canada’s government and offer free guided tours, where you can learn about the country’s political history. If you visit in summer, catch the Changing of the Guard ceremony or the stunning Northern Lights sound and light show projected onto the Parliament buildings at night.",
            },
            {
              paragraph: "📍 Location: Wellington Street, Ottawa",
            },
            {
              paragraph: "🎟 Cost: Free",
            },
          ],
        },
        {
          contentTitle: "2. Stroll Along the Rideau Canal",
          contentParagraph: [
            {
              paragraph:
                "The Rideau Canal, a UNESCO World Heritage Site, transforms throughout the seasons. In the summer, enjoy boating, kayaking, or cycling along its scenic paths. In the winter, it becomes the world’s largest skating rink, stretching nearly 8 kilometers through the city. Don’t forget to grab a Beavertail, a classic Ottawa pastry, while you're there!",
            },
            {
              paragraph: "📍 Location: Downtown Ottawa",
            },
            {
              paragraph: "🎟 Cost: Free (skate rental fees apply in winter)",
            },
          ],
        },
        {
          contentTitle: "3. Visit the National Gallery of Canada",
          contentParagraph: [
            {
              paragraph:
                'Art lovers will appreciate the National Gallery of Canada, home to an impressive collection of Canadian, Indigenous, and international artworks. The museum features famous works like the Group of Seven paintings and contemporary exhibits. Outside, don’t miss the giant spider sculpture "Maman"—a must-see for visitors!',
            },
            {
              paragraph: "📍 Location: 380 Sussex Drive",
            },
            {
              paragraph: "🎟 Cost: $20 for adults (discounts available for students, seniors, and families)",
            },
          ],
        },
        {
          contentTitle: "4. Discover the ByWard Market",
          contentParagraph: [
            {
              paragraph:
                "Ottawa’s historic ByWard Market is a lively neighborhood filled with restaurants, cafes, and shops. It’s the perfect place to grab a coffee, browse local artisan goods, and experience Ottawa’s nightlife. Try some famous poutine or visit Le Moulin de Provence for a Canada-shaped cookie (made famous by Barack Obama’s visit).",
            },
            {
              paragraph: "📍 Location: ByWard Market Square",
            },
            {
              paragraph: "🎟 Cost: Free (food and shopping at your own expense)",
            },
          ],
        },
        {
          contentTitle: "5. Explore Gatineau Park",
          contentParagraph: [
            {
              paragraph:
                "Just 15 minutes from downtown, Gatineau Park offers breathtaking natural beauty year-round. Go hiking to the Pink Lake Lookout, take a scenic drive along the Champlain Lookout, or visit in the fall to witness the spectacular autumn foliage. If you're a winter sports enthusiast, enjoy cross-country skiing or snowshoeing through the park.",
            },
            {
              paragraph: "📍 Location: Chelsea, Quebec (near Ottawa)",
            },
            {
              paragraph: "🎟 Cost: Free (parking fees may apply)",
            },
          ],
        },
        {
          contentTitle: "Final Thoughts",
          contentParagraph: [
            {
              paragraph:
                "Ottawa is full of rich history, culture, and outdoor activities. Whether you're wandering through Parliament Hill, skating on the Rideau Canal, or enjoying the art scene, there’s something for everyone in this capital city.",
            },
            {
              paragraph:
                "Have you been to Ottawa? What’s your favorite thing to do?",
            },
          ],
        },
      ],
    },
    author: "AI",
    date: "January 30, 2025",
  },
  {
    id: "post5",
    title: "5 Must-Do Activities in Toronto",
    imageTitle: image5,
    content: {
      contentSumari:
        "Toronto, Canada’s largest city, is a vibrant metropolis packed with culture, entertainment, and iconic landmarks. Whether you're visiting for the first time or you're a local looking for something new, there’s always something exciting to explore. From world-class attractions to hidden gems, here are five must-do activities in Toronto.",
      contentBody: [
        {
          contentTitle: "1. Visit the CN Tower and EdgeWalk",
          contentParagraph: [
            {
              paragraph:
                "No trip to Toronto is complete without a visit to the CN Tower. Standing at 553 meters tall, this iconic landmark offers breathtaking views of the city skyline and Lake Ontario. If you're feeling adventurous, take part in the EdgeWalk, where you can walk hands-free on a ledge 116 stories above the ground—an adrenaline-pumping experience you won’t forget!",
            },
            {
              paragraph:
                "📍 Location: 290 Bremner Blvd",
            },
            {
              paragraph:
                "🎟️ Admission: Starts at $43 for the observation deck",
            },
          ],
        },
        {
          contentTitle: "2. Explore the Distillery District",
          contentParagraph: [
            {
              paragraph:
                "If you love history, art, and good food, the Distillery District is a must-visit. This pedestrian-only neighborhood is home to charming 19th-century buildings, boutique shops, galleries, and cozy cafés. Visit during the winter to experience the famous Toronto Christmas Market, or in the summer for open-air festivals and live music.",
            },
            {
              paragraph:
                "📍 Location: 55 Mill St",
            },
            {
              paragraph:
                "💡 Tip: Try the artisanal chocolate at SOMA Chocolatemaker!",
            },
          ],
        },
        {
          contentTitle: "3. Stroll Through Kensington Market",
          contentParagraph: [
            {
              paragraph:
                "One of Toronto’s most eclectic neighborhoods, Kensington Market is a cultural hotspot filled with vintage shops, global cuisine, and colorful street art. This bohemian district is perfect for a leisurely afternoon of shopping, people-watching, and tasting delicious international foods, from Jamaican patties to authentic Mexican tacos.",
            },
            {
              paragraph:
                "📍 Location: Around Augusta Ave & Baldwin St",
            },
            {
              paragraph:
                "🛍️ Don’t Miss: The Sunday pedestrian-only market days!",
            },
          ],
        },
        {
          contentTitle: "4. Catch a Game or Concert at Scotiabank Arena",
          contentParagraph: [
            {
              paragraph:
                "Toronto is a sports-loving city, and if you’re a fan, catching a Toronto Raptors (NBA) or Toronto Maple Leafs (NHL) game at Scotiabank Arena is an unforgettable experience. If sports aren’t your thing, the arena also hosts major concerts and events featuring international artists.",
            },
            {
              paragraph:
                "📍 Location: 40 Bay St",
            },
            {
              paragraph:
                "🎟️ Tickets: Prices vary depending on the event",
            },
          ],
        },
        {
          contentTitle: "5. Relax at Toronto Islands",
          contentParagraph: [
            {
              paragraph:
                "Just a short ferry ride from downtown, Toronto Islands offer a peaceful escape from the city's hustle and bustle. You can rent a bike, paddle around in a kayak, or simply relax on the sandy shores of Centre Island Beach. The islands provide stunning views of the Toronto skyline, making it a perfect spot for a picnic or sunset photoshoot.",
            },
            {
              paragraph:
                "📍 Location: Ferry departs from Jack Layton Ferry Terminal",
            },
            {
              paragraph:
                "⛴️ Ferry Cost: $9.11 round trip for adults",
            },
          ],
        },
        {
          contentTitle: "Final Thoughts",
          contentParagraph: [
            {
              paragraph:
                "Toronto is a city that never runs out of things to do. Whether you’re a thrill-seeker, a foodie, a sports fan, or someone who just loves to explore, there’s something for everyone. Which of these activities would you try first?",
            },
            {
              paragraph:
                "🚀 Want more travel tips? Stay tuned for more guides on the best places to visit!",
            },
          ],
        },
      ],
    },
    author: "AI",
    date: "January 30, 2025",
  },
  {
    id: "post6",
    title: "Top 5 Best Things to Do in Montreal",
    imageTitle: image6,
    content: {
      contentSumari:
        "Montreal, the vibrant heart of Quebec, is a city that blends European charm with North American energy. Whether you're a first-time visitor or a local looking to rediscover the city, Montreal offers a mix of history, culture, and entertainment that captivates everyone. Here are the top five things you must do in Montreal to make the most of your trip.",
      contentBody: [
        {
          contentTitle: "1. Explore Old Montreal and Notre-Dame Basilica",
          contentParagraph: [
            {
              paragraph:
                "📍 Must-Do:",
            },
            {
              paragraph:
                "- Visit the basilica in the evening for the AURA light show, an immersive visual and sound experience.",
            },
            {
              paragraph:
                "- Walk along Place Jacques-Cartier and enjoy the street performances and outdoor terraces.",
            },
          ],
        },
        {
          contentTitle: "2. Hike or Drive Up Mount Royal for an Incredible View",
          contentParagraph: [
            {
              paragraph:
                "Montreal gets its name from Mount Royal, the iconic hill that provides the best panoramic views of the city. Whether you prefer to hike, bike, or drive, reaching the Mount Royal Lookout is a must.",
            },
            {
              paragraph:
                "📍 Must-Do:",
            },
            {
              paragraph:
                "- Go for a sunrise or sunset hike for the most stunning views.",
            },
            {
              paragraph:
                "- During winter, try ice skating at Beaver Lake or tobogganing.",
            },
            {
              paragraph:
                "- In the summer, join the Tam-Tams festival on Sundays, where drummers and dancers gather for a lively outdoor celebration.",
            },
          ],
        },
        {
          contentTitle: "3. Indulge in Montreal’s Famous Food Scene",
          contentParagraph: [
            {
              paragraph:
                "Montreal is a paradise for food lovers, with iconic dishes that you can’t find anywhere else. The city’s culinary staples include:",
            },
            {
              paragraph:
                "🍽 Must-Try Foods:",
            },
            {
              paragraph:
                "- Poutine – Fries topped with cheese curds and smothered in gravy. Try it at La Banquise for the best variety.",
            },
            {
              paragraph:
                "- Montreal-style bagels – Smaller, denser, and sweeter than New York bagels, best enjoyed at St-Viateur Bagel or Fairmount Bagel.",
            },
            {
              paragraph:
                "- Smoked meat sandwich – A Montreal classic at Schwartz’s Deli, packed with flavor and history.",
            },
          ],
        },
        {
          contentTitle: "4. Discover the Underground City",
          contentParagraph: [
            {
              paragraph:
                "Montreal’s famous Underground City (RÉSO) is a unique way to explore the city, especially in the cold winter months. Spanning over 32 km (20 miles), it connects metro stations, shopping malls, restaurants, and even hotels—all underground!",
            },
            {
              paragraph:
                "📍 Must-Do:",
            },
            {
              paragraph:
                "- Shop at Eaton Centre and Place Montréal Trust for the best retail therapy.",
            },
            {
              paragraph:
                "- Visit Complexe Desjardins, a hub of entertainment with food courts and public events",
            },
            {
              paragraph:
                "- Walk through the art-filled Place des Arts station, which connects to Montreal’s main cultural venues.",
            },
          ],
        },
        {
          contentTitle: "5. Visit the Montreal Museum of Fine Arts or the Biodome",
          contentParagraph: [
            {
              paragraph:
                "For culture and nature lovers, Montreal offers incredible museums and immersive experiences. The Montreal Museum of Fine Arts (MMFA) is the largest art museum in Canada, featuring everything from contemporary to classical masterpieces. If you’re more into wildlife and ecosystems, the Montreal Biodome allows you to explore different habitats, including tropical rainforests and Arctic environments—all under one roof!",
            },
            {
              paragraph:
                "📍 Must-Do:",
            },
            {
              paragraph:
                "- At the MMFA, check out their rotating special exhibits featuring world-famous artists.",
            },
            {
              paragraph:
                "- At the Biodome, walk through the different climate zones and get up close with penguins, monkeys, and more.",
            },
          ],
        },
        {
          contentTitle: "Final Thoughts: Experience the Best of Montreal",
          contentParagraph: [
            {
              paragraph:
                "Montreal is a city that offers something for everyone—whether you're a history buff, foodie, or outdoor adventurer. From strolling the charming streets of Old Montreal to indulging in the city's famous cuisine and exploring its unique attractions, there's no shortage of amazing things to do.",
            },
            {
              paragraph:
                "Which of these activities would you love to try first?",
            },
          ],
        },
      ],
    },
    author: "AI",
    date: "January 30, 2025",
  },
  {
    id: "post7",
    title: "The 5 Best Things to Do in Spring in Canada",
    imageTitle: image7,
    content: {
      contentSumari:
        "Spring in Canada is a magical time when the snow melts away, flowers bloom, and wildlife emerges from hibernation. It’s the perfect season to explore the great outdoors, attend vibrant festivals, and experience the natural beauty of the country. If you're looking for the best ways to enjoy spring in Canada, here are five must-do activities:",
      contentBody: [
        {
          contentTitle: "1. Visit Tulip Festivals 🌷",
          contentParagraph: [
            {
              paragraph:
                "Spring in Canada wouldn’t be complete without a visit to one of its breathtaking tulip festivals. The Canadian Tulip Festival in Ottawa is one of the largest in the world, featuring millions of tulips in bloom across the city’s parks and gardens. This annual festival, held in May, celebrates the enduring friendship between Canada and the Netherlands.",
            },
            {
              paragraph:
                "In British Columbia, the Abbotsford Tulip Festival offers visitors the chance to stroll through stunning fields of colorful tulips set against the backdrop of majestic mountains. Whether you’re a photography enthusiast or just looking to soak in the beauty, these festivals are a must-visit.",
            },
          ],
        },
        {
          contentTitle: "2. See the Cherry Blossoms in Vancouver 🌸",
          contentParagraph: [
            {
              paragraph:
                "For a short but spectacular period in early spring, Vancouver transforms into a pink paradise during cherry blossom season. The Vancouver Cherry Blossom Festival (March–April) celebrates the thousands of cherry trees in full bloom throughout the city. Popular viewing spots include Stanley Park, Queen Elizabeth Park, and the University of British Columbia (UBC). This is a perfect time for a scenic walk, a picnic, or capturing breathtaking photos of nature’s beauty.",
            },
          ],
        },
        {
          contentTitle: "3. Go Wildlife Watching 🦌",
          contentParagraph: [
            {
              paragraph:
                "As the temperatures rise, Canada’s diverse wildlife begins to reappear, making spring an ideal time for wildlife watching.",
            },
            {
              paragraph:
                "- Whale Watching: Head to Tofino (BC), Bay of Fundy (NB), or Quebec’s Gulf of St. Lawrence to witness migrating whales. The waters come alive with gray, humpback, and orca whales.",
            },
            {
              paragraph:
                "- Bear Viewing: As the snow melts, black bears and grizzlies emerge from hibernation in places like Banff, Jasper, and Whistler. Seeing these majestic creatures in their natural habitat is an unforgettable experience.",
            },
          ],
        },
        {
          contentTitle: "4. Experience Maple Syrup Season 🍁",
          contentParagraph: [
            {
              paragraph:
                "Spring is also the season for one of Canada’s sweetest traditions—maple syrup season! Across Quebec, Ontario, and New Brunswick, sugar shacks (cabane à sucre) open their doors to visitors. Here, you can enjoy fresh maple syrup drizzled over pancakes, traditional maple taffy on snow, and live folk music. Quebec, in particular, is famous for its sugar shack experience, where families gather to enjoy delicious food and celebrate the season’s harvest.",
            },
          ],
        },
        {
          contentTitle: "5. Hike and Explore National Parks 🌿",
          contentParagraph: [
            {
              paragraph:
                "As the snow melts and nature awakens, Canada’s national parks offer some of the most breathtaking hiking opportunities in the world. Here are some of the best parks to explore in spring:",
            },
            {
              paragraph:
                "- Banff & Jasper National Parks (Alberta) – With fewer crowds than in summer, spring is a fantastic time to spot wildlife and take in the stunning mountain landscapes.",
            },
            {
              paragraph:
                "- Gros Morne National Park (Newfoundland & Labrador) – Featuring fjords, mountains, and coastal trails, this UNESCO World Heritage Site is a hiker’s dream.",
            },
            {
              paragraph:
                "- Bruce Peninsula National Park (Ontario) – Known for its crystal-clear turquoise waters and rugged cliffs, this park offers some of the best springtime hiking experiences.",
            },
          ],
        },
        {
          contentTitle: "Conclusion",
          contentParagraph: [
            {
              paragraph:
                "Spring in Canada is a time of renewal and beauty, offering countless opportunities to explore nature, celebrate traditions, and experience the country’s vibrant culture. Whether you’re admiring tulips in Ottawa, watching cherry blossoms in Vancouver, spotting wildlife in the wild, indulging in maple treats, or hiking through scenic parks, there’s something for everyone to enjoy. So, grab your camera, pack your bags, and get ready to embrace the magic of spring in Canada!",
            },
            {
              paragraph:
                "What’s your favorite springtime activity in Canada?",
            },
          ],
        },
      ],
    },
    author: "AI",
    date: "February 10, 2025",
  },
];

  export default posts;